<script setup lang="ts">
import { usePrintSettings } from "../../composables/usePrintSettings";
import MBtn from "../MBtn.vue";

interface Props {
  color?: "primary" | "secondary";
  variant?: "outlined";
  size?: "small" | "default";
}

// props
const props = withDefaults(defineProps<Props>(), {
  color: "primary",
  variant: undefined,
  size: "default",
});

// composables
const { printColors } = usePrintSettings();

// emits
const emit = defineEmits<{
  click: [];
}>();

// styles
const styles = {
  default: {
    backgroundColor: printColors.value[props.color],
    color: printColors.value[`on-${props.color}`],
  },
  outlined: {
    backgroundColor: "transparent",
    borderColor: printColors.value[props.color],
    color: printColors.value[props.color],
  },
};
</script>

<template>
  <MBtn
    v-bind="props"
    :style="styles[props.variant ?? 'default']"
    @click="emit('click')"
  >
    <slot />
  </MBtn>
</template>
